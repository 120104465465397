import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import { motion, useViewportScroll, useTransform } from "framer-motion"

import Menu from "../Menu"
import parse from "html-react-parser"

const Hero = ({ page }) => {
  const heroImage = getImage(page.featuredImage.node.localFile.childImageSharp)
  const clientLogo = getImage(
    page.portfolioSettings.clientLogo?.localFile?.childImageSharp
  )
  const { scrollYProgress } = useViewportScroll()
  const scaleAnim = useTransform(scrollYProgress, [0, 1], [1, 1.8])
  const opacityAnim = useTransform(scrollYProgress, [0, 0.8], [0, 1])

  return (
    <div
      style={{
        display: "grid",
      }}
    >
      <div
        style={{
          gridArea: "1/1",
          display: "grid",
          position: "relative",
          overflow: "hidden",
          height: "100vh",
          maxHeight: "100vh",
        }}
      >
        <div className="is-hidden-mobile">
          <motion.div initial={{ scale: 1 }} style={{ scale: scaleAnim }}>
            <BgImage
              style={{
                gridArea: "1/1",
                display: "grid",
                width: "100vw",
                height: "100vh",
                minWidth: "100vw",
              }}
              layout="fullWidth"
              alt=""
              image={heroImage}
              formats={["auto", "webp", "avif"]}
            />
          </motion.div>
        </div>
        <div className="is-hidden-desktop">
          <motion.div initial={{ scale: 1 }} style={{ scale: scaleAnim }}>
            <GatsbyImage
              style={{
                gridArea: "1/1",
                display: "grid",
                width: "100vw",
                height: "100vh",
                overflow: "hidden",
                minWidth: "100vw",
              }}
              alt=""
              image={heroImage}
              formats={["auto", "webp", "avif"]}
            />
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: "0.2" }}
          style={{
            opacity: opacityAnim,

            background: "rgba(0, 0, 0, 1)",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
          }}
        ></motion.div>
      </div>
      <div
        style={{
          gridArea: "1/1",
          display: "grid",
          background: "rgba(0,0,0,1)",
        }}
      >
        <div className="hero-img-background">
          <div className="container">
            <section className="hero is-fullheight">
              <div className="hero-head">
                <Menu />
              </div>
              <div className="hero-body"></div>
              <div className="hero-foot">
                <div
                  className="columns text-is-centered"
                  style={{ marginBottom: "7%" }}
                >
                  <div className="column is-6 is-offset-3 has-text-centered">
                    <GatsbyImage
                      image={clientLogo}
                      style={{ margin: "20px auto", maxWidth: "75vw" }}
                      alt=""
                    />
                    <div style={{ padding: "0px 16px" }}>
                      <div className="is-size-3 is-size-2-mobile has-text-weight-bold has-text-centered has-text-white">
                        {parse(page.portfolioSettings.heroLabel)}
                      </div>

                      <div className="is-size-4 is-size-4-mobile has-text-weight-semibold mb-6 mt-5  has-text-white">
                        {parse(page.portfolioSettings.heroDescription)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
