import React, { useEffect } from "react"
import { Link } from "gatsby"
import { motion, useAnimation } from "framer-motion"
import { useFirstProject } from "../../hooks/use-first-project"
import { useNextProject } from "../../hooks/use-next-project"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import parse from "html-react-parser"
import { useInView } from "react-intersection-observer"
const NextProject = ({ next, currentId }) => {
  const { nodes } = useFirstProject()
  const { edges } = useNextProject()

  var nextProject = null
  edges.forEach(function (edge) {
    if (edge.node.id === currentId) {
      nextProject = edge.next
    }
  })

  const firstProject = nodes[0]
  var uri = "",
    featuredImage = "",
    clientName = ""
  if (nextProject) {
    uri = nextProject.uri
    featuredImage = nextProject.featuredImage
    clientName = nextProject.portfolioSettings.clientName
  } else {
    uri = firstProject.uri
    featuredImage = firstProject.featuredImage
    clientName = firstProject.portfolioSettings.clientName
  }

  const nextHeroImage = getImage(featuredImage.node?.localFile?.childImageSharp)

  const imageVariants = {
    hidden: { scale: 1 },
    visible: {
      scale: 1.06,
      transition: {
        duration: 0.5,
      },
    },
  }
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
    if (!inView) {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <div className="next-project slide" style={{ overflow: "hidden" }}>
      <Link to={uri}>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={imageVariants}
        >
          <BgImage
            image={nextHeroImage}
            alt=""
            className="bg-image has-text-white"
            style={{
              width: "100%",

              display: "flex !important",
              opacity: "0.5",
            }}
          >
            <div
              className="bg-image-cover"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "100%",
              }}
            >
              <div className="container is-hidden-touch">
                <div
                  className="columns is-vcentered"
                  style={{
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  <div className="column is-9 has-text-left">
                    <h1
                      className="is-size-4 is-size-4-mobile has-text-yellow has-text-weight-semibold"
                      style={{ lineHeight: "1.9rem !important" }}
                    >
                      NEXT PROJECT
                    </h1>
                  </div>
                  <div className="column is-9 has-text-left">
                    <h1 className="is-size-2 is-size-2-mobile has-text-weight-bold has-text-white ">
                      {parse(clientName)}
                    </h1>
                  </div>
                </div>
              </div>

              <div
                className="container is-hidden-desktop"
                style={{ position: "relative" }}
              >
               
                <div class="columns is-mobile is-vcentered">
                <div className="column is-9 has-text-left">
                <h1
                  className="is-size-5-mobile is-size-5 has-text-yellow has-text-weight-semibold">
                  NEXT
                  PROJECT
                </h1>
                </div>
                  <div class="column is-9 has-text-left">
                    <h1
                      className="is-size-2 is-size-2-mobile has-text-weight-bold has-text-white "
                      style={{ textAlign: "left " }}
                    >
                      {parse(clientName)}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </BgImage>
        </motion.div>
      </Link>
    </div>
  )
}

export default NextProject
