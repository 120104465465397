import React from "react"
import { graphql } from "gatsby"
/* Indie Components */
import Seo from "../components/SEO"
import Hero from "../components/Project/Hero"
import MainContent from "../components/Project/MainContent"
import NextProject from "../components/Project/NextProject"

const ProjectTemplate = ({ data: { next, page } }) => {
  return (
    <div id="projectPage">
      <Seo seo={page.seo} />

      <Hero page={page} />

      <MainContent page={page} />
      <NextProject page={page} next={next} currentId={page.id} />
    </div>
  )
}
export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    page: wpProject(id: { eq: $id }) {
      slug
      id
      title
      date(formatString: "MMMM DD, YYYY")
      #use a fragment from src/fragments.js
      ...projectFields
    }
    # this gets us the previous post by id (if it exists)
    previous: wpProject(id: { eq: $previousPostId }) {
      uri
      title
      ...projectFields
    }

    # this gets us the next post by id (if it exists)
    next: wpProject(id: { eq: $nextPostId }) {
      uri
      title
      ...projectFields
    }
  }
`
