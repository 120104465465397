import { useStaticQuery, graphql } from "gatsby"

export const useNextProject = () => {
  const { allWpProject } = useStaticQuery(
    graphql`
      query ALL_PROJECTS {
        allWpProject(sort: { fields: portfolioOrder }) {
          edges {
            node {
              id
            }
            next {
              uri
              title
              ...projectFields
            }
          }
        }
      }
    `
  )

  return allWpProject
}
