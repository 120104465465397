import React from "react"
import parse from "html-react-parser"
import ProjectTestimonial from "../ProjectTestimonial"
import IndieLink from "../IndieLink"
const MainContent = ({ page }) => {
  return (
    <>
      <div
        className="section has-background-black-bis is-hidden-touch"
        style={{ paddingTop: "150px", paddingBottom: "140px" }}
      >
        <div className="container">
          <div className="columns">
            <div className="column is-4 is-offset-1">
              <h2
                dataAos="flip-left"
                className="is-size-4 has-text-weight-semibold has-text-white"
              >
                THE CHALLENGE
              </h2>
              <div className="is-size-5 has-text-weight-normal has-text-white">
                {parse(page.portfolioSettings.theChallenge)}
              </div>
            </div>
            <div className="column is-4 is-offset-2">
              <h2 className="is-size-4 has-text-weight-semibold has-text-white">
                THE RESULT
              </h2>
              <div className="is-size-5 has-text-weight-normal has-text-white">
                {parse(page.portfolioSettings.theResult)}
                {page?.clientName}
              </div>
            </div>
          </div>
          <div className="content-padding columns">
            <div className="column is-7  is-offset-1">
              <ProjectTestimonial
                testimonial={page.portfolioSettings.testimonial}
                credentials={page.portfolioSettings.testimonialMeta}
              />
            </div>
          </div>

          <div className="columns">
            <div className="content-padding column is-7  is-offset-1">
              <IndieLink
                text="VISIT SITE"
                url={page.portfolioSettings.siteLink}
                externalLink={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="project is-hidden-desktop">
        <div className="section  has-background-black-bis">
          <div className="container project" style={{ padding: "0px 32px" }}>
            <h3 className="is-size-3-mobile has-text-white has-text-weight-semibold">
              THE CHALLENGE
            </h3>
            <div className="is-size-5-mobile the-challenge body-text has-text-weight-normal has-text-white">
              {parse(page.portfolioSettings.theChallenge)}
            </div>
          </div>
        </div>
        <div className="section testimonial has-background-white">
          <div className="container">
            <ProjectTestimonial
              testimonial={page.portfolioSettings.testimonial}
              credentials={page.portfolioSettings.testimonialMeta}
              theme="light"
            />
          </div>
        </div>
        <div className="section last has-background-black-bis">
          <div className="container">
            <h3 className="is-size-3-mobile has-text-white has-text-weight-semibold">
              THE RESULT
            </h3>
            <div className="is-size-5-mobile body-text has-text-weight-normal has-text-white">
              {parse(page.portfolioSettings.theResult)}
            </div>
            <div className="content-padding">
              <IndieLink
                text="VISIT SITE"
                url={page.portfolioSettings.siteLink}
                externalLink={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainContent
