import React from "react"

import BodyText from "../components/BodyText"

const ProjectTestimonial = ({ testimonial, credentials, theme }) => {
  return (
    <div className="testimonial">
      <BodyText
        text={testimonial}
        size="is-size-4 is-size-4-mobile"
        weight="semibold"
        theme={theme}
      />
      <BodyText
        text={`- ${credentials}`}
        size="is-size-5 is-size-6-mobile"
        weight="normal"
        theme={theme}
        additionalClasses="credentials"
      />
    </div>
  )
}

export default ProjectTestimonial
