import { useStaticQuery, graphql } from "gatsby"

export const useFirstProject = () => {
  const { allWpProject } = useStaticQuery(
    graphql`
      query FIRST_PROJECT {
        allWpProject(limit: 1, sort: { fields: portfolioOrder }) {
          nodes {
            uri
            title
            ...projectFields
          }
        }
      }
    `
  )
  return allWpProject
}
